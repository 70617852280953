<template>
    <validation-provider
    :rules="rules"
    :name="name"
    v-bind="$attrs"
    v-slot="{ errors, valid, invalid, validated }"
    ref="form"
    >
        <a-form-item
        :label="label"
        :rules="rules"
        hasFeedback
        :validateStatus="
            valid && validated && successMessage
            ? 'success'
            : errors[0] && !error
            ? 'error'
            : invalid && validated
            ? 'warning'
            : null
        "
        :help="
            valid && validated && successMessage
            ? successMessage
            : errors[0] && !error
            ? errors[0]
            : null
        "
        >
            <a-radio-group
                name="name"
                :options="options"
                v-model="mutableValue"
                :disabled="disabled" 
                rules="rules" 
                v-on="listeners"
                v-bind="$attrs"
             >
            </a-radio-group>
        </a-form-item>
    </validation-provider>

</template>

<script>
export default{
    name: 'base-radio-group',
    props: {
        value: {
            type: undefined,
            default: null
        },
        name: {
            type: String,
            default: ''
        },
        options: {
            type: [String, Array, Object],
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default:''
        },
        rules: {
            type: [String, Array, Object],
            default: ''
        },
        defaultOption: {
            type: undefined,
            default: null
        },
        successMessage: {
        type: String,
        description: "Input success message",
        default: '',
        },
        error: {
        type: String,
        description: "Input error (below input)",
        }
    },
    data() {
        return {
            focused: false
        };
    },
    computed: {
        listeners(){
            return {
            ...this.$listeners,
            blur: this.onBlur,
            change: this.onChange
            };
        },
        slotData() {
            return {
                focused: this.focused,
                ...this.listeners,
            };
        },
        mutableValue: {
            get() {
                return this.value != null ? this.value : this.options[this.options.length-1].value;
            },
            set(check) {
                this.$emit("input", check);
            }
        }
    },
    methods: {
        onBlur(e) {
            this.focused = false;
            this.$emit("blur", e);
        },
        onChange(e){
            //this.$emit("change", e);
        }
    }
};
</script>
