
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import BaseRadioGroup from '@/components/Inputs/BaseRadioGroup.vue'
import { EstadoParticipacion } from '@/store/entities/OZONE/estadoParticipacion'
import CampanaTipo from '@/store/entities/OZONE/campanaTipo'

@Component({
  components: { Modal, BaseButton, BaseCheckbox, BaseRadioGroup }
})
export default class EditEstadoParticipacion extends AbpBase {
  name: 'edit-estados-participacion'
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) editing: boolean
  @Prop({ type: String }) id: string
  estadoParticipacion: EstadoParticipacion = new EstadoParticipacion();
  title: String = ''
  campanaTipo: CampanaTipo = null
  estadoInicial: boolean = true;
  disabled: boolean = false;
  options: Array<any> = [
    { label: 'Inicial', value: true },
    { label: 'Final', value: false }
  ];

  async created() {

  }

  async save() {
    if (this.checkFormValidity()) {
      if (this.editing) {
        await this.$store.dispatch({
          type: 'estadoParticipacion/update',
          data: this.estadoParticipacion
        })
      } else {
        await this.$store.dispatch({
          type: 'estadoParticipacion/create',
          data: this.estadoParticipacion
        })
      }

      this.$emit('save-success')
      this.$emit('input', false)
      this.resetFields()
    }
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  shown() {
    this.campanaTipo = null
    if (this.editing) {
      this.estadoParticipacion = Util.extend(true, {}, this.$store.state.estadoParticipacion.editEstadoParticipacion);
      this.estadoInicial = this.estadoParticipacion.estadoInicial;
      this.title = 'Editar estado';
    }
    else {
      this.title = 'Crear nuevo estado';
    }
    this.setDisabled()
  }
  checkFormValidity() {
    const valid = (this.$refs.estadoParticipacionForm as any).checkValidity()
    return valid
  }
  resetFields() {
    this.estadoParticipacion = new EstadoParticipacion()
  }

  @Watch('estadoInicial')
  valueTipoEstadoChange(value: any){
    this.estadoParticipacion.estadoInicial = value;
    this.estadoParticipacion.estadoFinal = !value;
  }

  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted('OZONE.EstadoParticipacion.Edit', 'OZONE.EstadoParticipacion.Create', 'OZONE.EstadoParticipacion.Delete')
  }

  estadoParticipacionRule = {
    nombre: {
      required: { required: true, min: 2, max: 32 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Nombre')),
      placeholder: this.L('Nombre'),
      trigger: 'blur'
    },
    estadoInicial: {
      required: { required: true },
      placeholder: this.L('Inicial'),
      trigger: 'blur'
    },
    estadoFinal: {
      required: { required: false },
      placeholder: this.L('Inicial'),
      trigger: 'blur'
    }
  }
}
