
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import EditEstadoParticipacion from './edit-estado-participacion.vue'
import CampanaTipo from '@/store/entities/OZONE/campanaTipo'
import PeopleFind from "../../../components/People/people-find.vue";

class PageEstadoParticipacionRequest extends PageRequest {
  nombre: string
  campanaTipo: CampanaTipo
}

@Component({
  components: {
    EditEstadoParticipacion,
    PeopleFind
  }
})
export default class EstadoParticipacions extends AbpBase {
  //filters
  pagerequest: PageEstadoParticipacionRequest = new PageEstadoParticipacionRequest()

  createModalShow: boolean = false
  editModalShow: boolean = false
  get list() {
    return this.$store.state.estadoParticipacion.list
  }
  get loading() {
    return this.$store.state.estadoParticipacion.loading
  }
  create() {
    this.createModalShow = true
  }
  editRow(item, index, button) {
    this.$store.commit('estadoParticipacion/edit', item)
    this.edit()
  }
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L('DeleteEstadoParticipacionConfirm'), {
        //title: this.L('Tips'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.L('Yes'),
        cancelTitle: this.L('No'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: 'estadoParticipacion/delete',
            data: item
          })
          await this.getpage()
        }
      })
  }
  edit() {
    this.editModalShow = true
  }

  pageChange(page: number) {
    this.$store.commit('estadoParticipacion/setCurrentPage', page)
    this.getpage()
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit('estadoParticipacion/setPageSize', pagesize)
    this.getpage()
  }
  async getpage() {
    
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize
    //filters

    await this.$store.dispatch({
      type: 'estadoParticipacion/getAll',
      data: this.pagerequest
    });

     const pagination = { ...this.pagination };
    pagination.total = this.$store.state.estadoParticipacion.totalCount;
    pagination.pageSize = this.$store.state.estadoParticipacion.pageSize;
    pagination.current = this.$store.state.estadoParticipacion.currentPage;
    this.pagination = pagination;
  }
  get pageSize() {
    return this.$store.state.estadoParticipacion.pageSize
  }
  get totalCount() {
    return this.$store.state.estadoParticipacion.totalCount
  }
  get currentPage() {
    return this.$store.state.estadoParticipacion.currentPage
  }
  set currentPage(page) {
    this.$store.commit('estadoParticipacion/setCurrentPage', page)
    this.getpage()
  }
  columns = [
    {
      title: this.L('Nombre'),
      dataIndex: 'nombre',
      thClass: 'w-auto',
      tdClass: 'w-auto'
    },
    {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ]

  async created() {
    this.getpage()
  }

   pagination: any = {};
  findModalShow: boolean = false;

  searchDefinition: {}[] = [
    {
      fieldName: "Nombre",
      fieldType: "text",
      fieldBind: "nombre",
      value: "",
    },
  ];

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit(
      "estadoParticipacion/setCurrentPage",
      pagination.current
    );
    //this.getpage();

    await this.getpage();
  };

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
      } else {
        delete this.pagerequest[element.fieldBind];
      }
    });

    //this.pagerequest.idcampaña =;
    await this.getpage();
    this.findModalShow = false;
  };
}
